import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const SlideTutorialItem = ({
    title,
	content,
	imageSrc,
	imageAlt,
}) => {

	return (
		<div
			className='apl-slidetutorial--item'
		>
			<GatsbyImage image={imageSrc} alt={imageAlt} />
			<div className='apl-slidetutorial--item--content'>
                <h3>{title}</h3>
				<p>{content}</p>
			</div>
		</div>
	)
}

SlideTutorialItem.displayName = 'SlideTutorialItem'

SlideTutorialItem.propTypes = {
    title: PropTypes.string,
	content: PropTypes.string,
	imageSrc: PropTypes.object,
	imageAlt: PropTypes.string,
}

SlideTutorialItem.defaultProps = {
    title: null,
	content: null,
	imageSrc: null,
	imageAlt: null,
}

export default SlideTutorialItem
