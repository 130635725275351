import React from 'react'
import PropTypes from 'prop-types'

const Reason = ({ children, title }) => {

	return (
		<>
			<div className='apl-reason'>
				<div className='container'>
					<h2>{title}</h2>
					<div className='is-hidden-touch'>
						<div className='columns is-multiline'>{children}</div>
					</div>
				</div>
			</div>
			<div className='apl-reason--mobile is-hidden-desktop'>
				{children}
			</div>
		</>
	)
}

Reason.displayName = 'Reason'

Reason.propTypes = {
	children: PropTypes.any,
	title: PropTypes.string,
}

Reason.defaultProps = {
	children: null,
	title: null,
}

export default Reason
