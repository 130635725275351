import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout/Layout'
import Seo from '../components/Seo'
import Slide from '../components/slide/Slide'
import SlideItem from '../components/slide/SlideItem'
import Reason from '../components/reason/Reason'
import ReasonItem from '../components/reason/ReasonItem'
import SlideTutorial from '../components/slide-tutorial/SlideTutorial'
import SlideTutorialItem from '../components/slide-tutorial/SlideTutorialItem'
import ContactForm from '../components/contact-form/ContactForm'

import useSiteMetadata from '../hooks/useSiteMetadata'

import thumbnailImage from '../images/warpin-favicon.png'

const IklanPintar = ({ data, location }) => {
	const [hash, setHash] = useState(location?.hash)
	const { siteUrl } = useSiteMetadata()

	const sliderData = data.wpgHeadlessQuery.acfSlideBanner.slideBannerItem
	const seoData = data.wpgHeadlessQuery.seo

	console.log(sliderData)

	return (
		<Layout
			location={location}
			logo={data.logoImage}
			setHash={setHash}
			hash={hash}
		>
			<Seo
				title={seoData.title}
				canonical={`${siteUrl}${location.pathname}`}
				meta={[
					{ name: 'description', content: seoData.metaDesc },
					{ name: 'keywords', content: seoData.metaKeywords },
					{
						name: 'robots',
						content: `${seoData.metaRobotsNoindex}, ${seoData.metaRobotsNofollow}`,
					},
					{
						property: 'og:url',
						content: `${siteUrl}${location.pathname}`,
					},
					{ property: 'og:title', content: seoData.opengraphTitle },
					{
						property: 'og:description',
						content: seoData.opengraphDescription,
					},
					{ property: 'og:type', content: seoData.opengraphType },
					{
						property: 'og:site_name',
						content: seoData.opengraphSiteName,
					},
					{
						property: 'og:image',
						content:
							seoData.opengraphImage !== null
								? seoData.opengraphImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:title', content: seoData.twitterTitle },
					{
						name: 'twitter:description',
						content: seoData.twitterDescription,
					},
					{
						name: 'twitter:image',
						content:
							seoData.twitterImage !== null
								? seoData.twitterImage.localFile.publicURL
								: thumbnailImage,
					},
					{ name: 'twitter:card', content: 'summary' },
					{ name: 'twitter:site', content: '@warungpintarco' },
				]}
			/>

			<section className='slide-banner'>
				<Slide>
					{sliderData.map((itemData, index) => (
						<SlideItem
							key={index}
							title={itemData.title}
							content={itemData.content}
							buttonLabel={itemData.buttonLabel}
							buttonLink={itemData.buttonLink}
							logoSrc={getImage(itemData.logoSrc.localFile)}
							imageSrc={getImage(itemData.imageSrc.localFile)}
							imageXsSrc={getImage(itemData.imageXsSrc.localFile)}
							withButton={itemData.withButton}
							withLogo={true}
							logoSlim={true}
							bgPosition='center bottom'
						/>
					))}
				</Slide>
			</section>

			<section className='reason' id='alasan'>
				<Reason title='Kenapa Iklan Pintar?'>
					<ReasonItem
						title='Efisien'
						content='Cara terbaik <strong>menjangkau warung</strong> secara <strong>offline</strong> maupun <strong>online</strong>'
						imageSrc={getImage(data.reasonImage.nodes[0])}
						imageAlt='wpg-illustrator'
					/>
					<ReasonItem
						title='Targeted Campaign'
						content='Promosi yang <strong>didesain khusus sesuai tujuan Brand</strong>. Termasuk penyesuaian demografi, tipe warung dan area sekitar'
						imageSrc={getImage(data.reasonImage.nodes[1])}
						imageAlt='wpg-illustrator'
					/>
					<ReasonItem
						title='Transparan'
						content='<strong>Visibilitas yang jelas</strong> terhadap proses operasional. <strong>Kampanye fleksibel</strong> sesuai kebutuhan brand <strong>berdasarkan data real-time</strong>'
						imageSrc={getImage(data.reasonImage.nodes[2])}
						imageAlt='wpg-illustrator'
					/>
					<ReasonItem
						title='Segment insight yang andal'
						content='<strong>Optimasi pengembangan produk</strong> dan buat kampanye yang sesuai'
						imageSrc={getImage(data.reasonImage.nodes[3])}
						imageAlt='wpg-illustrator'
					/>
				</Reason>
			</section>

			<section className='tutorial-1' id='audiens'>
				<SlideTutorial
					title='Menjangkau audiens anda'
					subtitle='1. Menjangkau <strong>pemilik warung</strong>'
					text='iklan online & Aktivasi lewat aplikasi'
					content='Pemilik warung adalah penentu produk mana yang akan dijual di warungnya dan punya pengaruh besar di komunitas sekitarnya'
					bgColor='#FECF28'
				>
					<SlideTutorialItem
						title='Official store di aplikasi warung pintar'
						content='Eksposur brand secara eksklusif di halaman utama aplikasi Warung Pintar'
						imageSrc={getImage(data.slideTutorial.nodes[0])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Ikon kategori produk di aplikasi warung pintar'
						content='Eksposur brand dalam kategori produk di aplikasi Warung Pintar'
						imageSrc={getImage(data.slideTutorial.nodes[1])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Digital Ads di Aplikasi Warung pintar'
						content='Tingkatkan brand awareness lewat aplikasi Warung Pintar'
						imageSrc={getImage(data.slideTutorial.nodes[2])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='WhatsApp Blast'
						content='Komunikasi langsung dengan Juragan lewat channel komunikasi sehari-hari'
						imageSrc={getImage(data.slideTutorial.nodes[3])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='stiker whatsapp'
						content='Cara seru memperluas channel pemasaran brand'
						imageSrc={getImage(data.slideTutorial.nodes[4])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Gamifikasi di aplikasi warung pintar'
						content='Tingkatkan penjualan SKU lewat skema gamifikasi yang menguntungkan pengguna setia'
						imageSrc={getImage(data.slideTutorial.nodes[5])}
						imageAlt='wpg-illustrator'
					/>
				</SlideTutorial>
			</section>

			<section className='tutorial-2'>
				<SlideTutorial
					subtitle='2. Menjangkau <strong>konsumen akhir</strong>'
					text='promosi offline di warung dan aktivasi produk'
					content='Langsung berhubungan dengan konsumen akhir. Brand bisa melihat hasil promosi melalui laporan rinci layanan kami'
					bgColor='#FFEFBB'
				>
					<SlideTutorialItem
						title='Aset promosi offline di warung'
						content='Menarik perhatian konsumen saat datang ke warung. Ditambah akses laporan kampanye harian'
						imageSrc={getImage(data.slideTutorial.nodes[6])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Product Sampling di Warung'
						content='Perkenalkan produk Anda langsung ke konsumen akhir dan dapatkan insight produk secara langsung'
						imageSrc={getImage(data.slideTutorial.nodes[7])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Eksposur sosial media warung pintar'
						content='Menyebarkan informasi  dari brand/principal lewat Official Store Warung Pintar di marketplace'
						imageSrc={getImage(data.slideTutorial.nodes[8])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='Eksposur di Official Store E-commerce warung pintar'
						content='Menyebarkan informasi dari brand/principal lewat Official Store Warung Pintar di marketplace'
						imageSrc={getImage(data.slideTutorial.nodes[9])}
						imageAlt='wpg-illustrator'
					/>
					<SlideTutorialItem
						title='AKTIVASI REFERRAL DI WARUNG MITRA'
						content='Ciptakan awareness terhadap layanan anda dengan kanal baru kampanye referral'
						imageSrc={getImage(data.slideTutorial.nodes[10])}
						imageAlt='wpg-illustrator'
					/>
				</SlideTutorial>
			</section>

			<section className='contact-form'>
				<ContactForm
					title='Punya pertanyaan?'
					subtitle='Hubungi kami! Mari diskusikan apa yang bisa kami bantu untuk bisnis Anda!'
					endpoint='https://getform.io/f/e403cc8a-9b83-4ef4-8af7-cd86c7a99c78'
					lang={false}
					isBFF={true}
					imageSrc={getImage(data.contactBackgrounds.nodes[3])}
					imageXsSrc={getImage(data.contactBackgrounds.nodes[7])}
					maxWidth={true}
				/>
			</section>
		</Layout>
	)
}

export const queryData = graphql`
	{
		wpgHeadlessQuery(slug: { eq: "iklan-pintar" }) {
			id
			slug
			seo {
				canonical
				title
				metaDesc
				metaKeywords
				metaRobotsNofollow
				metaRobotsNoindex
				opengraphDescription
				opengraphSiteName
				opengraphTitle
				opengraphType
				opengraphUrl
				opengraphImage {
					localFile {
						publicURL
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					localFile {
						publicURL
					}
				}
			}
			acfSlideBanner {
				slideBannerItem {
					buttonLabel
					buttonLink
					content
					title
					withButton
					logoSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageXsSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1200)
							}
						}
					}
					imageSrc {
						localFile {
							childImageSharp {
								gatsbyImageData(width: 1600)
							}
						}
					}
				}
			}
		}
		reasonImage: allFile(
			filter: { relativeDirectory: { eq: "illustrations/reason" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		slideTutorial: allFile(
			filter: {
				relativeDirectory: { eq: "illustrations/slide-tutorial" }
			}
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 600)
				}
			}
		}
		contactBackgrounds: allFile(
			filter: { relativeDirectory: { eq: "illustrations/contact-forms" } }
			sort: { order: ASC, fields: [name] }
		) {
			nodes {
				childImageSharp {
					gatsbyImageData(width: 1440)
				}
			}
		}
		logoImage: file(relativePath: { eq: "warpin-logo-inverse.png" }) {
			childImageSharp {
				gatsbyImageData(width: 800)
			}
		}
	}
`

export default IklanPintar
