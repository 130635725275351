import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'

const SlideTutorial = ({ children, title, subtitle, text, content, bgColor }) => {
	const settings = {
		dots: true,
        centerMode: false,
        infinite: true,
		variableWidth: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	return (
		<div
			className={`apl-slidetutorial`}
            style={{ backgroundColor: bgColor }}
		>
			<div className='container'>
				<div className='apl-slidetutorial--wrapper'>
					<h2>{title}</h2>
                    <h3 dangerouslySetInnerHTML={{ __html: subtitle }}></h3>
                    <h4>{text}</h4>
                    <p>{content}</p>
				</div>
                <Slider {...settings}>{children}</Slider>
			</div>
		</div>
	)
}

SlideTutorial.displayName = 'SlideTutorial'

SlideTutorial.propTypes = {
	children: PropTypes.any,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	text: PropTypes.string,
	content: PropTypes.string,
	bgColor: PropTypes.string,
}

SlideTutorial.defaultProps = {
	children: null,
	title: null,
	subtitle: null,
	text: null,
	content: null,
	bgColor: null,
}

export default SlideTutorial
