import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const ReasonItem = ({ title, content, imageSrc, imageAlt }) => {
	return (
		<>
			<div className='column is-3 is-hidden-touch'>
				<div className='apl-reason--item'>
					<GatsbyImage
						image={imageSrc}
						alt={imageAlt}
					/>
					<h3>{title}</h3>
					<p dangerouslySetInnerHTML={{ __html: content }}></p>
				</div>
			</div>
			<div className='apl-reason--item--mobile is-hidden-desktop'>
				<GatsbyImage
					image={imageSrc}
					alt={imageAlt}
				/>
				<h3>{title}</h3>
				<p dangerouslySetInnerHTML={{ __html: content }}></p>
			</div>
		</>
	)
}

ReasonItem.displayName = 'ReasonItem'

ReasonItem.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
	imageSrc: PropTypes.object,
	imageAlt: PropTypes.string,
}

ReasonItem.defaultProps = {
	title: null,
	content: null,
	imageSrc: null,
	imageAlt: null,
}

export default ReasonItem
